@import 'spec/settings/index';
@import 'spec/tools/index';
@import "~bootstrap/scss/bootstrap";
@import 'spec/index';
@import 'vendor/index';

.table-striped td {
    padding: 0.2rem !important;
    vertical-align: baseline;
    border-top: 1px solid rgba(0, 0, 0, 0.0625);
}

.table-striped thead th,
.table-striped tfoot th {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    vertical-align: bottom !important;
    border-top: 0px;
}

.table-striped tr.odd {
    background-color: #f9f9f9 !important;
}

.table-striped tbody tr:hover {
    background-color: $md-light-blue-100 !important;
}

#loader {
    transition: all 0.3s ease-in-out;
    opacity: 1;
    visibility: visible;
    position: fixed;
    height: 100vh;
    width: 100%;
    background: #fff;
    z-index: 90000;
}

#loader.fadeOut {
    opacity: 0;
    visibility: hidden;
}

.spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-scale-out 1.0s infinite ease-in-out;
    animation: sk-scale-out 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scale-out {
    0% {
        -webkit-transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scale-out {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}


img {
    max-width: 100%;
}

.menu-btn {
    padding: 10px 15px;
    border: none;
    background: none;
}

table.dataTable tr th:last-of-type {
    min-width: 95px;
}

.select2-container{
    min-width: 100%;
    max-width: 100%;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-dropdown {
    border-color: #ced4da !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    height: 35px !important;
    line-height: 2.4 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 35px !important;
}

.select2-container .select2-selection--single {
    height: 35px !important;
}

.form-control-file {
    border: 1px solid #cbcbcb;
    padding: 5px;
}

.dataTables_wrapper{
    overflow: auto;
}

.big-info-counter-box {
    border: 0px;
    border-radius: 6px;
    background-color: $default-white;
    -webkit-box-shadow: 0px 4px 15px 2px rgba(0,0,0,0.05);
    box-shadow: 0px 4px 15px 2px rgba(0,0,0,0.05);
    padding:10px;
}

.big-info-counter-box .title {
    font-size: 18px;
}

.big-info-counter-box .counter {
    font-size: 24px;
    text-align: right;
}
